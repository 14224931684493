import { DropdownMenu } from "@/app/components/DropdownMenu/DropdownMenu";
import { MultiSelectDropdownMenu } from "@/app/components/MultiSelectDropdownMenu/MultiSelectDropdownMenu";
import Table from "@/app/components/Table/Table";
import Tabs, { TabType } from "@/app/components/Tabs/Tabs";
import { useClientStore } from "@/app/stores/clientStore";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { Edit05, Share03, Star01 } from "@untitled-ui/icons-react";
import { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import { useGetClientByClientId } from "@/app/hooks/clientHook";
import {
  useSetFavoriteClient,
  useUpdateMonitorAssignees,
} from "@/app/hooks/monitorHook";
import { useUser } from "@auth0/nextjs-auth0/client";
import { useGetAllUsers } from "@/app/hooks/organizationHook";

interface Assignee {
  userId: string;
  organizationId?: string;
  phoneNumber?: string;
  socialInsuranceNumber?: string;
  address?: string;
  citizenship?: string;
  city?: string;
  state?: string;
}

interface TableRow {
  creditUsed: number;
  assignees: Assignee[];
  dbt: number;
}

export const CompaniesToWatchTable = () => {
  // Navigation functionality
  const router = useRouter();

  // Switch favorite state mutation
  const { mutateAsync: setFavoriteClientMutation } = useSetFavoriteClient();

  // Update Assignees mutation
  const { mutateAsync: updateAssigneesMutation } = useUpdateMonitorAssignees();

  const [selectedClientId, setSelectedClientId] = useState<number>(0);
  const [allEmployeesData, setAllEmployeesData] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [tableFavoritesData, setTableFavoritesData] = useState<any[]>([]);
  const [tableState, setTableState] = useState<"loading" | "error" | "success">(
    "loading"
  );
  const [selectedTab, setSelectedTab] = useState<"All" | "Favorites">("All");

  // Checking, Fetching, and Setting Client Account store
  const { clientData } = useClientStore();
  const { data } = useGetClientByClientId(selectedClientId);

  // Get user state
  const { user, isLoading: isUserLoading, error: userError } = useUser();

  // Get all Employees
  const { data: employeesData, isLoading: usersLoading } = useGetAllUsers(
    user?.org_id?.toString() || ""
  );

  useEffect(() => {
    if (clientData && clientData.allMonitorClients) {
      console.log("client Data", clientData.allMonitorClients.data);
      let newData = clientData.allMonitorClients.data.map((client) => ({
        ...client,
        assignees: client.assignees || [], // Ensure assignees are always treated as an array
      }));

      setTableData(newData);
      setTableState(clientData.allMonitorClients.state);
      setTableFavoritesData(newData.filter((client) => client.isFavorite));
    }
  }, [clientData, selectedTab]);

  useEffect(() => {
    if (employeesData) {
      setAllEmployeesData(employeesData);
    }
  }, [employeesData]);

  const navigateToClient = (client: any) => {
    router.push(`/accountManagement/${client.id}`);
  };

  const favoriteClient = async (clientId: string, isFavorite: boolean) => {
    await setFavoriteClientMutation({ monitorId: clientId, isFavorite });
    setTableData((current) =>
      current.map((item) => {
        if (item.id === clientId) {
          return { ...item, isFavorite: !item.isFavorite }; // Toggle favorite status
        }
        return item;
      })
    );
    setTableFavoritesData((current) =>
      current.map((item) => {
        if (item.id === clientId) {
          return { ...item, isFavorite: !item.isFavorite }; // Toggle favorite status
        }
        return item;
      })
    );
  };
  // const updateAssigness = (rowIndex: number, assignees: any) => {
  //   let formattedAssignees = assignees.map((assignee: any) => ({
  //     userId: assignee,
  //   }));
  //   updateAssigneesMutation({
  //     monitorId: rowIndex,
  //     data: { assignees: formattedAssignees },
  //   });
  // };

  const renderCreditPulseScoreBadge = (
    pulseScore: number,
    previousPulseScore?: number
  ) => {
    if (pulseScore > 0 && pulseScore <= 3) {
      return (
        <span className="inline-flex items-center rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-600">
          {pulseScore}
        </span>
      );
    } else if (pulseScore > 3 && pulseScore <= 7) {
      return (
        <span className="inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-600">
          {pulseScore}
        </span>
      );
    } else if (pulseScore > 7) {
      return (
        <span className="inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-600">
          {pulseScore}
        </span>
      );
    } else {
      return (
        <span className="inline-flex items-center rounded-full bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
          ?
        </span>
      );
    }
  };

  const testOptions: any[] = [
    {
      name: "Open Link",
      icon: (
        <Share03 className="hover:text-creditpulse-500 hover:cursor-pointer" />
      ),
      onClick: (e: any) => {
        navigateToClient(e);
      },
    },
    {
      name: "Open Link",
      icon: <Edit05 />,
      onClick: (e: any) => console.log("Edit Link Test"),
    },
  ];

  const columnHelper = createColumnHelper<any>();

  const columns: ColumnDef<any>[] = [
    {
      accessorFn: (row) => row.isFavorite,
      id: "isFavorite",
      header: () => "",
      cell: (info) =>
        info.row.original.isFavorite ? (
          <span
            className="cursor-pointer text-lg"
            onClick={() => favoriteClient(info.row.original.id, false)}
          >
            ⭐️
          </span> // Filled star when favorited
        ) : (
          <Star01
            className="cursor-pointer text-gray-300"
            onClick={() => favoriteClient(info.row.original.id, true)}
          /> // Default icon when not favorited
        ),
      footer: (info) => info.column.id,
    },
    {
      accessorFn: (row) => row.company?.name,
      id: "companyName",
      header: () => "Company",
      cell: (info) => {
        return (
          <a
            className="font-bold hover:cursor-pointer hover:text-creditpulse-green-600"
            onClick={(e) => navigateToClient(info.row.original)}
          >
            {info.getValue() as string}
          </a>
        );
      },
      footer: (info) => info.column.id,
    },
    {
      accessorFn: (row) => row.latestPulseScores?.creditPulse,
      id: "creditPulse",
      header: () => "Pulse Score",
      cell: (info) => {
        return (
          <span className="font-bold">
            {renderCreditPulseScoreBadge(info.getValue() as number)}
          </span>
        );
      },
      footer: (info) => info.column.id,
    },
    {
      accessorFn: (row) => row.latestCreditsafeReport?.report?.paymentData?.dbt?.dbt,
      id: "dbt",
      header: () => "DBT",
      cell: (info) => {
        return <span className="">{info.getValue() as string}</span>;
      },
      footer: (info) => info.column.id,
    },
    {
      accessorFn: (row) => row.creditExtended || 0,
      id: "creditExtended",
      header: () => "Credit Extended",
      cell: (info) => (
        <span className="">
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(Number(info.getValue()))}
        </span>
      ),
      footer: (info) => info.column.id,
    },
    {
      accessorFn: (row) => row.creditUsed || 0,
      id: "creditUsed",
      header: () => "Credit Utilized",
      cell: (info) => (
        <span className="">
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(Number(info.getValue()))}
        </span>
      ),
      footer: (info) => info.column.id,
    },
    {
      accessorFn: (row) => row.company.industry,
      id: "companyIndustry",
      header: () => "Industry",
      cell: (info) => {
        return (
          <span className="max-w-[50px] truncate">
            {info.getValue() as string}
          </span>
        );
      },
      footer: (info) => info.column.id,
    },
    {
      accessorFn: (row) => row.name,
      id: "name",
      header: () => "Events",
      cell: (info) => {
        return <span className="font-bold">--</span>;
      },
      footer: (info) => info.column.id,
    },
    // {
    //   accessorFn: (row) => row.assignees,
    //   id: "assignees",
    //   header: () => "Assignees",
    //   cell: (info) => {
    //     const assignees: any[] = (info.getValue() as any[]) ?? [];
    //     const formattedAssignees = allEmployeesData.map((emp: any) => ({
    //       name: `${emp.given_name} ${emp.family_name}`,
    //       value: emp.user_id,
    //     }));
    //     const existingAssigneeValues = assignees.map(
    //       (assignee) => assignee.userId
    //     );

    //     return (
    //       <MultiSelectDropdownMenu
    //         placeHolderText="Select Assignees"
    //         menuItems={formattedAssignees}
    //         onMenuChoiceChange={(selectedUserIds) => {
    //           console.log("Selected User IDs:", selectedUserIds);
    //           updateAssigness(info.row.original.id, selectedUserIds);
    //         }}
    //         defaultValues={existingAssigneeValues}
    //       />
    //     );
    //   },
    //   footer: (info) => info.column.id,
    // },
    // Define other columns as needed
  ];
  return (
    <>
      <Table
        tableTitle="Companies To Watch"
        primary={true}
        //data={clientData?.allMonitorClients?.data || []}
        data={tableFavoritesData}
        columns={columns}
        status={tableState}
        //onRowClick={(row) => navigateToClient(row)}
      />
    </>
  );
};
